<template>
  <div class="footer" :style="{ marginTop }">
    <div class="footer_content pr clearfix">
      <div class="fc_top">
        <img
          src="../assets/images/footer/designer_logo1.png"
          alt=""
          class="designer_logo"
          style="margin-bottom: 10px"
        />
        <ul>
          <li>
            <router-link to="/linkInfo/about_v3_lib/" class="nick">
              关于我们
            </router-link>
          </li>
          <li>
            <router-link to="/linkInfo/problem_v3_lib/" class="nick">
              常见问题
            </router-link>
          </li>
          <li>
            <router-link to="/linkInfo/privacy_lib/" class="nick">
              服务协议
            </router-link>
          </li>
          <li>
            <router-link to="/linkInfo/privacy_v2_lib/" class="nick">
              隐私政策
            </router-link>
          </li>
          <li>
            <router-link to="/linkInfo/right_v3_lib/" class="nick">
              版权声明
            </router-link>
          </li>
        </ul>
        <img src="../assets/images/footer/mobile_icon.png" alt="" />
        <p class="mobile">020-87573780</p>
        <img src="../assets/images/footer/mail2_icon.png" alt="" />
        <p class="net">yhsjxxk@qq.com</p>
        <img src="../assets/images/footer/address_icon.png" alt="" />
        <p>广州市天河区元岗路310号智汇park创意园D座3楼</p>
      </div>
      <div class="fc_bottom">
        <img
          src="../assets/images/footer/goyor_logo.png"
          alt=""
          class="goyor_logo"
          style="margin: 40px 0 10px"
        />
        <p>
          Copyright © 2011-{{ currentYear }} 广州冠岳网络科技有限公司版权所有
        </p>
        <p>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="record-link"
            >粤ICP备09217733号</a
          >
        </p>
        <p>
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602003224"
            target="_blank"
            class="record-link"
            >粤公网安备44010602003224号</a
          >
        </p>
        <p>Guangdong-YihuiAI-20240314</p>
      </div>
      <img src="../assets/images/footer/erweima.jpg" alt="" class="code" />
      <p class="code_tit">翼狐设计学习库</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    marginTop: {
      type: String,
      default: "36px",
    },
  },
  data() {
    return {
      currentYear: "20xx",
    };
  },
  mounted() {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #2f2f2f;

  p {
    margin-right: 30px;
  }

  .fc_top {
    ul {
      display: inline-block;

      li {
        display: inline-block;
        margin-right: 30px;

        .nick {
          color: #787878;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.footer_content {
  width: 1210px;
  position: relative;
  padding: 35px 0;
  margin: 0 auto;
  color: #787878;
}

.designer_logo,
.goyor_logo {
  display: block;
}

.footer .footer_content img {
  margin-right: 5px;
  vertical-align: middle;
}

.footer .footer_content p {
  display: inline-block;
  margin-top: 14px;
  font-size: 12px;
}

.footer .record-link {
  color: #787878;
}

.footer .code {
  width: 146px;
  position: absolute;
  right: -4px;
  top: 40px;
}

.footer .code_tit {
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  right: -15px;
  top: 185px;
}
</style>